const SET_PROFILE_STATE = "SET_PROFILE_STATE";

interface InitialState {
    [key: string]: any;
    profile_initialized: false;
}

export interface StoreActions {
    set: (state, config) => void;
    update: (state, config) => void;
}
export interface WrappedActions {
    set: (config) => void;
    update: (config) => void;
}

export interface StateDefinition {
    reducer: (state: InitialState, action: { type: string; payload: any }) => void;
    actions: StoreActions;
}

let default_state: InitialState = {
    profile_initialized: false
};

let reducer = (state = default_state, action) => {
    switch (action.type) {
        case SET_PROFILE_STATE: {
            return Object.assign({}, action.payload);
        }
        default: {
            return state;
        }
    }
};

let actions: StoreActions = {
    set: (state, config) => {
        return {
            type: SET_PROFILE_STATE,
            payload: Object.assign({ profile_initialized: true }, config)
        };
    },
    update: (state, new_profile_settings) => {
        return {
            type: SET_PROFILE_STATE,
            payload: Object.assign({ profile_initialized: true }, state, new_profile_settings)
        };
    }
};

export let state_definition: StateDefinition = {
    reducer: reducer,
    actions: actions
};
